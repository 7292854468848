import React, { Component } from 'react';
import Music from './information/Music';
import Sport from './information/Sport';
import Linguistics from './information/Linguistics';

export default class AMOI extends Component {
    render = () : JSX.Element => {
        return (
        <ul className='container__list'>
            <Linguistics /> 
            <Music />
            <Sport />
        </ul>
    )}
}
