import React, { Component } from 'react';
import { BlogAdminPanel } from '../admin';
import TodoistDisplay from '../todoistIntegration';

type Props = {
    page: number
}

export default class DashboardContent extends Component<Props> {
    selectPage = () : JSX.Element => {
        switch (this.props.page) {
            case 0:
                window.location.assign('/')
                return <div />;
            case 1:
                return React.createElement(BlogAdminPanel)
            case 2:
                return React.createElement(TodoistDisplay)
            default:
                return <div />;
        }
    }

    render = () : JSX.Element => {
        return this.selectPage()
    }
}