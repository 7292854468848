import React from 'react';
import { Link } from "react-router-dom";

export default class Router extends React.Component {
    render = () : JSX.Element => {
      return (
        <div id='navbar'>
          <div className="navigator__text">
            <nav className="navigator">
              <Link to="/" className='link'>Home</Link> |{" "}
              <Link to="/about" className='link'>About Me</Link> |{" "}
              <Link to="/Events" className='link'>Events</Link> |{" "}
              <Link to="/portfolio" className='link'>Portfolio</Link> |{" "}
              <Link to="/blog" className='link'>Blog</Link> |{" "}
              <Link to="/map" className='link'>Map</Link> |{" "}
              <Link to="/github" className='link'>Github</Link> |{" "}
              <Link to="/linkedin" className='link'>LinkedIn</Link> 
            </nav>
          </div>
        </div>
    )
  }
}
