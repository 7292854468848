import React, { Component } from 'react';

export default class Sport extends Component {
    render = () : JSX.Element => {
        return (
        <div className='container'>
            <li>
                <div className='container__heading'>
                    <h3>Sport: </h3>
                </div>
            </li>
            <ul className='container__list'>
                <li>
                    <div>
                        <p>Figure Skating, from 2017 - 2020</p>
                    </div>
                </li>
            </ul>
        </div>
    )}
}