import React, { Component } from 'react';
import Button from './Button'

type Props = {
    onPageChange(page : number):  void
}

type State = {
    elem: HTMLCollectionOf<Element>
}

export default class Navbar extends Component<Props, State> {
    handleLogout = () : void => {
        localStorage.removeItem('tokenRyan');
        window.location.assign("/");
    }

    render = () : JSX.Element => {
        return(
            <div className="container">
                <div className="container container__text">
                    <div className="navlink" id=".text">
                        <Button name="Home" page={0} color="" onPageChange={this.props.onPageChange}/>
                    </div>
                    <div className="navlink" id=".text">
                        <Button name="Blog" page={1} color="" onPageChange={this.props.onPageChange}/>
                    </div>
                    <div className="navlink" id=".text">
                        <Button name="Todo" page={2} color="" onPageChange={this.props.onPageChange}/>
                    </div>
                    <div className="navlink" id=".text">
                        <Button name="Exit" page={-1} color="" onPageChange={this.handleLogout}/>
                    </div>
                </div>
            </div>
        )
    }
}