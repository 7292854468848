import React, { Component } from 'react';

export default class Music extends Component {
    render = () : JSX.Element => {
        return (
        <div className='container'>
            <li>
                <div className='container__heading'>
                    <h3>Music: </h3>
                </div>
            </li>
            <ul className='container__list'>
                <li>
                    <div className='container__second-heading'>
                        <p>I'm currently learning:-</p>
                    </div>
                    <div>
                        <ul className='container__list'>
                            <li>Singing (Classical)</li>
                        </ul>
                    </div>
                </li>
                <li>
                    <div className='container__second-heading'>
                        <p>I enjoy listening to:-</p>
                    </div>
                    <div>
                        <ul className='container__list'>
                            <li>Classical</li>
                            <li>Recommend Me!</li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    )}
}

