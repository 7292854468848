import React, { Component } from 'react';

type Post = {
    title:        string,
    author:       string,
    publish_date: string,
    content:      string
}

type Props = {
    post: Post
}

export default class BlogPost extends Component<Props> {
    render = () : JSX.Element => {
        return(
            <div className='blog-post__content'>
                <h2>{this.props.post.title}</h2>
                <h3>{this.props.post.author}</h3>
                <b>{this.props.post.publish_date}</b>
                <p>{this.props.post.content}</p>
            </div>
        )
    }
}