import React, { Component } from 'react';
import axios from 'axios';
import config from '../../config/config.json';

type State = {
    title:   string,
    author:  string,
    content: string,
    p:       boolean,
}

export default class BlogAdminPanel extends Component<{}, State> {
    constructor(props : {}) {
        super(props);
        this.state = {
            title: '',
            author: '',
            content: '',
            p: false,
        };

        axios.post(config.host + '/users/auth', (localStorage.getItem('tokenRyan')), {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "X-Requested-With"
            }
        })
        .then(res => {
            if (res.status !== 200) {
                window.location.assign('/');
            }
        }) 
        .catch(err => {
            console.log(err);
        });

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleAuthorChange = this.handleAuthorChange.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleTitleChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void => {
        this.setState({title: event.currentTarget.value});
    }

    handleAuthorChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void => {
        this.setState({author: event.currentTarget.value});
    }

    handleContentChange = (
        event: React.FormEvent<HTMLTextAreaElement>,
    ): void => {
        this.setState({content: event.currentTarget.value});
    }

    handleLogout = () : void => {
        localStorage.removeItem('tokenRyan');
        window.location.assign("/");
    }

    handleSubmit = (
        event: React.FormEvent,
    ) : void => {
        event.preventDefault();
        
        let tempDate = new Date();
        let date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate();

        const data = {
            token: localStorage.getItem("tokenRyan"),
            title: this.state.title,
            author: this.state.author,
            content: this.state.content,
            publish_date: date,
        };
        //console.log(data);
        axios.post(config.host + '/posts/', (data), {})
        .then(res => {
            //console.log(res);

            if (res.data.success === true) {
                window.location.reload();
            }
            else {
                this.setState({p: true});
            }
            
        })
        .catch(err => {
            console.log(err);
        })
    }   

    failedToSubmit = () : JSX.Element => {
        if (this.state.p === true) {
            return(
                <div>
                    <p style={{color: 'red', fontSize: 14}}>Failed to send post!</p>
                </div>
            )
        }
        return(
            <div></div>
        )
    }

    render = () : JSX.Element => {
        return(
            <div className='container container--theme-dark'>
                <div>
                    <h1>New Blogpost</h1>
                </div>
                <div className='container__form'>
                    <form onSubmit={this.handleSubmit} >
                        <input name='title' placeholder='Title' className='container__blog-input' type='text' value={this.state.title} onChange={this.handleTitleChange} /><br/>
                        <input name='author' placeholder='Author' className='container__blog-input' type='text' value={this.state.author} onChange={this.handleAuthorChange} /><br/>
                        <textarea name='content' className='container__blog-input-content' rows={20} cols={100} value={this.state.content} onChange={this.handleContentChange}></textarea>
                        <br/>
                        <button className='container__login-input-s' value='Post' >Post</button>{this.handleSubmit}
                    </form>
                    <br/>
                    <button className='container__login-input-s' value='Logout' onClick={this.handleLogout} >Logout</button>
                </div>
            </div>
        )
    }
}