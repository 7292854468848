import React, { Component } from 'react';
import axios from 'axios';
import TodoistProject from './TodoistProject';

import config from '../../config/config.json';

type Proj = {
    id: number,
    name: string,
    title: string
}

type State = {
    projects: Proj[],
    status: string
}

export default class TodoistList extends Component<{}, State> {
    constructor(props : {}) {
        super(props);

        this.state = {
            projects: [],
            status: ""
        };

        let body = {
            token: localStorage.getItem('tokenRyan')
        };

        axios.post(config.host + '/apiTokens/todoist/projects', body).then(respo => {
            console.log(respo.data.status);
            if (respo.data.status === "failure") {
                console.log(localStorage.getItem('tokenRyan'));
                this.setState({status: "failure"});
            }
            else {
                this.setState({
                    projects: respo.data.data,
                    status: "success"
                });
            }
        });
    }

    generate = () : (JSX.Element | null)[] => {
        var ProjectData = this.state.projects;
        var ProjectStuff = ProjectData.map(proj => {
            if (proj.name === "Inbox") return null;
            return (
                <TodoistProject project={proj} key={proj.title} /> 
            )
        })
        return ProjectStuff;
    }

    render = () : JSX.Element => {
        return(
            <table>
                <thead>
                    <tr>
                        <th scope="col">Project<br/></th>
                        <th scope="col">Tasks Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    {this.generate()}
                </tbody>
            </table>
        )
    }
} 
