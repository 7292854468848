import React, { Component } from 'react';
import axios from 'axios';
import config from '../../config/config.json';

type Task = {
    id: number,

}

type Proj = {
    id: number,
    name: string
}

type State = {
    tasks: Task[],
    remaining: number
}

type Props = {
    project: Proj
}

export default class TodoistProject extends Component<Props, State> {
    constructor(props : Props) {
        super(props);
        this.state = {
            tasks: [],
            remaining: 0
        };
        axios.post(config.host + '/apiTokens/todoist/tasks', {
            token: localStorage.getItem('tokenRyan'),
            projectID: this.props.project.id
        })
        .then(res => {
            this.setState({tasks: res.data});
            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].completed === false){
                    this.setState({remaining: this.state.remaining + 1});
                }
            }
            
        })
        .catch(err => {
            console.log(err)
        });
    }

    render = () : JSX.Element => {
        return (
            <tr>
                <td style={{textAlign: "right"}}>{this.props.project.name + " |"} </td>
                <td>{this.state.tasks.length}</td>
            </tr>
        )
    }
}