import React, { Component } from 'react';

export default class Linguistics extends Component {
    render = () : JSX.Element => {
        return (
        <div className='container'>
            <li>
                <div className='container__heading'>
                    <h3>Linguistics: </h3>
                </div>
            </li>
            <ul className='container__list'>
                <li>
                    <div>
                        <p>
                            I enjoy learning linguistics.
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    )}
}