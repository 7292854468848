import React, { Component } from 'react';
import Router from './Router';

type Props = {
    title: string
}

export default class Heading extends Component<Props> {
    render = () : JSX.Element => {
        return (
            <div>
                <h1>{this.props.title}</h1>
                <Router />
            </div>
        )
    }
}
