import React, { Component } from 'react';
import Skills from './information/Skills';
import Experience from './information/Experience';
import Education from './information/Education';

export default class AMIList extends Component {
    render = () : JSX.Element => {
        return (
        <ul className='container__list'>
            <Experience />
            <Skills />
            <Education />
        </ul>
    )}
}

