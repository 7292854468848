import React, { Component } from 'react';
import AMIList from './AboutMeInformationList';
import AMOI from './AboutMeOtherInf';
import { Heading, Footer } from '../util';


export default class AboutMe extends Component {
    render = () : JSX.Element => {
        return (
        <div className='container'>
            <div className="container container--theme-dark">
                <div className='container__heading'>
                    <Heading title='About Me!' />
                </div>
                <div className='container__text'>
                    <br/><br/>
                    I am currently a Software Engineer at ANZ in Australia.
                    
                </div>
            </div>
            <div className='container container--theme-light'>
                <h2>Working Information</h2>
                <AMIList />
            </div>
            <div className='container container--theme-dark'>
                <h2>Some of my other interests!</h2>
                <AMOI />
            </div>
            <Footer />
        </div>
    )}
}

