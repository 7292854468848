import React, {Component} from 'react';
import EventsExposure from './EventsExposure';
import {Heading, Footer} from '../util';

export default class EventsPage extends Component {
    render = () : JSX.Element => {
        return (
            <div className='container'>
                <div className="container container--theme-dark">
                    <div className="container__heading">
                        <Heading title='Here are some events that I have attended!'/>
                    </div>
                    <div className='container__text'>
                        <br/><br/>
                        Here's a list of my activities
                        
                    </div>
                </div>
                <div className='container container--theme-light'>
                    <EventsExposure/>
                </div>
                <Footer />
            </div>
        )
    }
}