import React, { Component } from 'react';
import Axios from 'axios';
import Project from './Project';
import config from '../../config/config.json';

type Proj = {
    id: number,
    name: string,
    languages: string,
    description: string
}

type State = {
    projects: Proj[];
}

export default class ProjectList extends Component<{}, State> {
    constructor(props : {}) {
        super(props);
        this.state = {
            projects: []
        }

        Axios.get(config.host + '/projects/')
        .then(res => {
            this.setState({projects: res.data});
            this.setState({projects: this.state.projects.sort((a, b) : number =>  {
                return b.id - a.id
            })})
        })
    }

    generate = () : JSX.Element[] => {
        var ProjectsData = this.state.projects;
        var c = false;
        var ProjectsStuff = ProjectsData.reverse().map(project => {
            c = !c
            return (
                <div key={project.name}>
                    <Project data={project} theme={(c) ? 'container container--theme-light' : 'container container--theme-dark'}/>
                    
                </div>
            )
        })
        return ProjectsStuff;
    }

    render = () : JSX.Element => {
        return (
            <div>
                {this.generate()}
            </div>
        )
    }
}