import React, { Component } from 'react';
import { Heading, Footer } from '../util';
import BlogList from './BlogList';

export default class Blog extends Component {
    render = () : JSX.Element => {
        return(
            <div className='container'>
                <div className='container container--theme-dark'>
                    <Heading title="Here's my Blog" />
                    <br/><br/>
                    Some thoughts
                </div>
                <div className='container__blog-list container--theme-light'>
                    <BlogList />
                </div>
                <Footer />
            </div>
        )
    }
}