import React, { Component } from 'react';

type Props = {
    data: {
        name:        string,
        workingDate: string,
        description: string
    }
}

export default class Event extends Component<Props> {
    render = () : JSX.Element => {
        return (
            <div>
                <li>
                    <div className='container__heading'>
                        <p><b>{this.props.data.workingDate}</b></p>
                    </div>
                </li>
                <ul className='container__list'>
                    <li>
                        <div>
                            <p><i>{this.props.data.name}</i></p>
                            <p>
                                {this.props.data.description}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}