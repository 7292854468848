import React, { Component } from 'react';
import TodoistList from './TodoistList';
import axios from 'axios'
import config from '../../config/config.json'


export default class TodoistDisplay extends Component {
    constructor(props : {}) {
        super(props)

        axios.post(config.host + '/users/auth', (localStorage.getItem('tokenRyan')))
        .then(res => {
            if (res.status !== 200) {
                window.location.assign('/');
            }
        }) 
        .catch(err => {
            console.log(err);
        });
    }
    render() {
        // if (localStorage.getItem('tokenRyan') === null) {
        //     return (
        //         <div className='container container--theme-dark'>
        //             <div>
        //                 <Heading title="You... Don't really have access to this page..." />
        //             </div>
        //         </div>
        //     )
        // } else {
        return(
            <div className='container'>
                <div className='container container--theme-dark'>
                    <TodoistList />
                    <br/>
                </div>
            </div>
        )

    }

}