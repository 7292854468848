import React, { Component } from 'react';
import axios from 'axios';
import BlogPost from './BlogPost';

import config from '../../config/config.json';

type Post = {
    title: string,
    author: string,
    publish_date: string,
    content: string
}

type State = {
    posts: Post[]
}

export default class BlogList extends Component<{}, State> {
    constructor(props : {}) {
        super(props);
        this.state = {
            posts: []
        };

        axios.get(config.host + '/posts/')
        .then(res => {
            this.setState({posts: res.data});
            
        });
    }

    generate = () : JSX.Element[] => {
        var PostData = this.state.posts;
        var PostsStuff = PostData.reverse().map(post => {
            return (
                <div className='container--theme-w75 container__blog-post' key={post.title}>
                    <BlogPost post={post} />
                </div>
            )
        })
        return PostsStuff;
    }

    render = () :JSX.Element => {
        return(
            <div className="container__blog-list">
                {this.generate()}
            </div>
        )
    }

}

