import React, { Component } from 'react';

export default class Skills extends Component {
    render = () : JSX.Element => {
        return (
        <div className='container'>
            <li>
                <div className='container__heading'>
                    <h3>Skills/Tool: </h3>
                </div>
            </li>
            <ul className='container__list'>
                <li>
                    <div>
                        <p>Go | JavaScript (ExpressJS Sequelizer) | HTML/CSS | SQL | Java | C | C#</p>
                    </div>
                </li>
                <li>
                    <div>
                        <p>MVC | Git | Trello | Agile | Salesforce SysAdmin (training)</p>
                    </div>
                </li>
            </ul>
        </div>
    )}
}