import React, { Component } from 'react';
import {Heading} from '../util';
import axios from 'axios';

import config from '../../config/config.json';

type State = {
    username: string,
    password: string,
    p:        boolean
}

export default class AdminLogin extends Component<{}, State> {

    constructor(props : {}) {
        super(props);
        this.state = {
            username:'',
            password:'',
            p: false,
        };
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleUsernameChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void =>  {
        this.setState({username: event.currentTarget.value});
    }

    handlePasswordChange = (
        event: React.FormEvent<HTMLInputElement>,
    ): void => {
        this.setState({password: event.currentTarget.value});
    }

    renderErrorMessage = () : JSX.Element => {
        if (this.state.p === true) {
            return(
                <div>
                    <p style={{
                        color: "red",
                        fontSize: 14,
                        margin: 0,
                        padding: 0,
                        paddingBottom: 10
                    }}>
                        Wrong credentials
                    </p>
                </div>
            )
        }
        return(
            <div></div>
        )
    }

    handleSubmit = (
        event: React.FormEvent,
    ): void => {
        event.preventDefault();
        
        const data = {
            username: this.state.username,
            password: this.state.password,
            
        }
        //console.log(data);
        axios.post(config.host + '/users/', data)
        .then(res => {
            if (res.data.status === "failed") {
                this.setState({p: true});
            }
            else {
                console.log(res);
                localStorage.setItem('tokenRyan', res.data.token);
                window.location.assign("/");
            }

        })
        .catch(err => {
            console.log(err);
        })
    }   

    render = () : JSX.Element => {
        return (
            <div className='container container--theme-dark'>
                <div>
                    <Heading title="Admin Login" />
                </div>
                <br/>
                <div className='container__form'>
                    <div className='card' >
                        <form onSubmit={this.handleSubmit} >
                            <input 
                                name='username' 
                                placeholder='Username here...' 
                                className='container__login-input' 
                                type='text' value={this.state.username} 
                                onChange={this.handleUsernameChange}
                            />
                            <input 
                                name='password' 
                                placeholder='Password here...' 
                                className='container__login-input' 
                                type='password' 
                                value={this.state.password} 
                                onChange={this.handlePasswordChange} 
                            />
                            {this.renderErrorMessage()}
                            <button className='container__login-input-s' value='Login' >Login</button>
                           
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}