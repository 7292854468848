import React, { Component } from 'react';
import { Link } from "react-router-dom";


export default class Footer extends Component {
    showlogin = () : JSX.Element => {
        if (localStorage.getItem('tokenRyan') !== null) {
            return (
                <div>
                    <Link to="/logout" className='link--footer'>
                        Logout 
                    </Link>{'        '}
                    <Link to="/admin-dashboard" className='link--footer'>
                        | Admin-Panel
                    </Link>
                </div>
            )
        }
        return(
            <div>
                <Link to="/login" className='link--footer'>@Admin</Link>
            </div>
        )
    }

    handleLogout = () : void => {
        localStorage.removeItem('tokenRyan');
        window.location.assign("/");
    }

    render = () : JSX.Element => {
        return(
            <div className='container__footer'>
                <div className='container__footer-text'>
                    {this.showlogin()}
                </div>
            </div>
        )
    }

}