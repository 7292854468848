import React, { Component } from 'react';
// import { BlogAdminPanel } from '../admin';
import Navbar from './Navbar'
import Axios from 'axios';
import DashboardContent from './DashboardContent';
import config from '../../config/config.json'

type State = {
    type: number
}

export default class Dashboard extends Component<{}, State> {
    constructor(props : {}) {
        super(props)

        this.state = {
            type: 1
        }

        Axios.post(config.host + '/users/auth', (localStorage.getItem('tokenRyan')), {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        })
        .then(res => {
            if (res.status !== 200) {
                window.location.assign('/');
            }
        }) 
        .catch(err => {
            console.log(err);
        });
    }

    pageChangeEvent = (newPage : number) : void => {
        this.setState({type: newPage})
    }

    render = () : JSX.Element => {
        return (
            <div className='container'>
                <div className='container container--theme-dark' style={{flexDirection: "row"}}>
                    <div className='container__side-menu'>
                        <Navbar onPageChange={this.pageChangeEvent}/>
                    </div>
                    <div className='container__admin-panel-content'>
                        <DashboardContent page={this.state.type}/>
                    </div>
                </div>
            </div>
        )
    }
}