import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import {Router, Link} from "@reach/router";
import { BrowserRouter as  Router, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import { AboutMe } from './components/aboutMe';
import Portfolio from './components/portfolio';
import { Blog } from './components/blog';
import { BlogAdminPanel } from './components/admin';
import { EventsPage } from './components/EventsExposure';
import { AdminLogin } from './components/auth';
import {Logout} from './components/util/index';
import TodoistDisplay from './components/todoistIntegration';
import {MapView} from './components/maps';
import { Dashboard } from './components/dashboard';



ReactDOM.render(
        <Router>
            <Route path="/" exact component={App} />
            <Route path="/about" component={AboutMe}/>
            <Route path="/portfolio" component={Portfolio}/>
            <Route path="/events" component={EventsPage}/>
            <Route path="/blog" component={Blog}/>
            <Route path="/login" component={AdminLogin}/>
            <Route path="/blog-admin" component={BlogAdminPanel}/>
            <Route path="/todoist-list" component={TodoistDisplay}/>
            <Route path="/logout" component={Logout} />
            <Route path="/map" component={MapView} />
            <Route path="/admin-dashboard" component={Dashboard}/>
            <Route path='/github' component={() => {
                window.location.href = 'https://github.com/ryanchew3';
                return null;
            }}/>
            <Route path='/linkedin' component={() => {
                window.location.href = 'https://linkedin.com/in/ryanchew3';
                return null;
            }}/>
        </Router>
    ,
    document.getElementById('root')
);
//<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
