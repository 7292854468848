import React, { Component } from 'react';
import { Heading, Footer } from '../util';
import ProjectList from './ProjectList';

export default class Portfolio extends Component<{}, {}> {
    render = () : JSX.Element => {
        return (
            <div className='container'>
                <div className="container container--theme-dark">
                    <div  className='container__heading'>
                        <Heading title="Here's some of the projects I've created!"/>
                    </div>
                    <div className='container__text'>
                        <br/><br/>
                        Just a small snippet
                    </div>
                
                </div>
                <ProjectList/>
                <Footer />
            </div>
        )
    }
}