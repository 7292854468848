import React, { Component } from 'react';

export default class Experience extends Component {
    render = () : JSX.Element => {
        return (
        <div className='container'>
            <li>
                <div className='container__heading'>
                    <h3>Experience: </h3>
                </div>
            </li>
            <ul className='container__list'>
                <li>
                    <div>
                        <p>ANZ - February 2020 -&gt; Present</p>
                    </div>
                </li>
                <li>
                    <div>
                        <p>Aiculus - June 2019 -&gt; November 2019</p>
                    </div>
                </li>
            </ul>
        </div>
    )}
}

