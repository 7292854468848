import React, { Component } from 'react';

export default class Logout extends Component {

    componentDidMount = () : void => {
        if (localStorage.getItem('tokenRyan') !== "null") {
            localStorage.removeItem('tokenRyan');
        }
        window.location.assign('/');
    }

    render = () : JSX.Element => {
        return(
            <div>
                <h1>Logging out</h1>
            </div>
        )
    }

}