import React, { Component } from 'react';
import myface from './AkagiMiria.jpg';
import './App.css';
import { Heading, Footer } from './components/util';
import { Helmet } from 'react-helmet';

export default class App extends Component {

  render = () : JSX.Element => {
    return (
      <div className="container">
        <Helmet>
          <meta charSet='utf-8' />
          <meta name='description' content='This is a Web portfolio for Ryan Chew in software and web development' />
          <title>Ryan's Portfolio and Blog Website</title>
          <link rel='canonical' href='http://me.ryan.moe' />
        </Helmet>
        <div className="container container--theme-dark">
          <img 
            src={myface} 
            className="image" 
            style={{borderRadius: 400}} 
            alt={"Oops! Looks like my face isnt here"} 
          />
          <div className="container__heading">
            <Heading title="Hi! I'm Ryan. Welcome to my site :)" />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}