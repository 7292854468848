import React, { Component } from 'react';

type Props = {
    theme: string;
    data: {
        name: string,
        languages: string,
        description: string,
    }
}

type State = {}

export default class Project extends Component<Props, State> {
    render = () : JSX.Element => {
        return (
        <div className={this.props.theme}>
            <div>
                <h2>
                    {this.props.data.name}
                </h2>
            </div>
            <div className='container__text'>
                <p><b>Created with: </b>{this.props.data.languages}</p>
                <p>
                    {this.props.data.description}
                </p>
            </div>
        </div>
    )}
}
