import React, { Component } from 'react';
import Axios from 'axios';
import Event from './Event';
import config from '../../config/config.json';

type event = {
    id:          number,
    name:        string,
    workingDate: string,
    description: string
}

type State = {
    events: event[]
}

export default class EventsExposure extends Component<{}, State> {
    constructor(props : {}) {
        super(props);
        this.state = {
            events: []
        }

        Axios.get(config.host + '/events')
        .then(res => {
            this.setState({events: res.data});
        })
    }

    generate = () : JSX.Element[] => {
        var EventsData = this.state.events;
        var EventsStuff = EventsData.reverse().map((e : event) => {
            return (
                <Event data={e} key={e.id} />
            )
        })
        return EventsStuff;
    }

    render = () : JSX.Element => {
        return (
            <div className='container__list'>
                {this.generate()}
            </div>
        )
    }
}