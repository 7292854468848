import React, {Component} from 'react';

export default class Education extends Component {
    render = () : JSX.Element => {
            return (
            <div className='container'>
                <li>
                    <div className='container__heading'>
                        <h3>Education:</h3>
                    </div>
                </li>
                <ul className='container__list'>
                    <li>
                        <div>
                            <p><b>2018-2019</b></p>
                        </div>
                        <div>
                            <p>RMIT University : Master of Computer Science</p>
                        </div>
                        
                    </li>
                    <li>
                        <div>
                            <p><b>2014-2017</b></p>
                        </div>
                        <div>
                            <p>Melbourne University : Bachelor of Science</p>
                        </div>
                        
                    </li>
                </ul>
            </div>
        )
    }
}
