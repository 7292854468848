import React, {Component} from 'react';
import { Heading } from '../util';

export default class MapView extends Component {
    render = () : JSX.Element => {
        return(
            <div className='container'>
                <div className=' container container--theme-dark'>
                    <Heading title="A map from Google" />
                    <br />
                    <iframe title="googlemapintegration" width="80%" height="650" frameBorder="0" style={{border: 0}} src="https://www.google.com/maps/embed/v1/view?zoom=13&center=-37.8136%2C144.9631&key=AIzaSyDiHOcS-VrjYArsAiY51Erz94RucV09-8I" allowFullScreen></iframe>
                </div>
            </div>
        )
    }
}