import React, {Component} from 'react';

type Props = {
    name: string,
    page: number,
    color: string, // hex code or rbg

    onPageChange(page : number):  void
}

export default class Button extends Component<Props> {
    handleClick = () : void => {
        this.props.onPageChange(this.props.page)
    }

    // TODO: Add button css
    render = () : JSX.Element => {
        return(
            <button className="container__side-menu__button" onClick={this.handleClick}>
                <h3>{this.props.name}</h3>
            </button>
        )
    }
}